/* Version Heather  */
/* :root {
  --light-background: #c9f1de;
  --light-background: white;
  --contrasting-background: #d3b5d6;
  --contrast-border: black;
  --darker-background: #d3b5d6;
  --shop-card-background: #ebdfeb;
  --shop-card: white;
  --shop-card-shadow: #533d5f;
  --map-zoom-bar: #d3b5d6;
  --map-filter-background: #ebdfeb;
  --button-color: #dcddfd;
  --button-hover: #a382ef;
  --button-active: white;
  --quality-color: #DDE0BD;
  --brand-color: #a8c1ec;
  --highlight-color: #f2cef5;
  --lighter-highlight: #fefbfe;
  --link-normal: black;
  --link-hover: #6B29BC;
  --link-active: white;
  --dark-text: black;
  --dark-border: black;
  --loader-main-color: #d3b5d6;
  --loader-point-color: #855489;
  --loader-size: 5px;
} */

/* Version Lavender  */
:root {
  --light-background: white;
  --contrasting-background: #D7D8FD;
  --contrast-border: black;
  --darker-background: #BBBDFA;
  --shop-card-background: #D7D8FD;
  --shop-card: white;
  --shop-card-shadow: #533d5f;
  --map-zoom-bar: #BBBDFA;
  --map-filter-background: #D7D8FD;
  --button-color: #D7D8FD;
  --button-hover: #a382ef;
  --button-active: white;
  --quality-color: #f0efc4;
  --brand-color: #E6F4F1;
  --highlight-color: #f2cef5;
  --lighter-highlight: #fefbfe;
  --link-normal: black;
  --link-hover: #6B29BC;
  --link-active: white;
  --dark-text: black;
  --dark-border: black;
  --loader-main-color: #D7D8FD;
  --loader-point-color: #898df5;
  --loader-size: 5px;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--shop-card-background);
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* roboto-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v30-latin-regular.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/roboto-v30-latin-italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v30-latin-italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/roboto-v30-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v30-latin-700.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/roboto-v30-latin-700italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
    url('./fonts/roboto-v30-latin-700italic.woff') format('woff');
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~MOBILE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.app {
  width: 100%;
  height: 100vh;
  background-color: var(--contrasting-background);
}

a:link {
  color: var(--link-normal);
}

a:visited {
  color: var(--link-normal);
}

a:hover {
  color: var(--link-hover)
}

a:active p {
  color: var(--link-active);
}

.header-container {
  width: 100%;
  height: 102px;
  background-color: var(--light-background);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}

.header-placeholder {
  width: 100%;
  height: 102px;
  display: block;
  background-color: var(--shop-card-background);
}

.header__header-logo {
  width: 290px;
  height: 98px;
  padding: 20px;
}

.navbar {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  background-color: var(--darker-background);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  color: var(--dark-text);
  z-index: 10;
}

.navbar-item {
  padding: 5px 10px;
}

.active .navbar-item {
  color: black;
  background-color: var(--map-filter-background)
}

.navbar a {
  text-decoration: none;
}

.ad-space-desktop-container {
  display: none;
}

.ad-space-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50px;
  z-index: 2
}

.ad-space-below-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 30px;

}

.ad-space {
  width: 320px;
  height: 50px;
  margin: auto;
  background-color: white;
  font-size: 12px;
  font-style: italic;
  z-index: 2;
}

.ad-space-wrapper-home {
  display: none
}

.ad-space-wrapper-home-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;
}

.loader-container {
  width: 100%;
  height: calc(100% - 161px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loader {
  background-color: var(--loader-main-color);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 30;
}

.loader__element {
  border-radius: 100%;
  border: var(--loader-size) solid var(--loader-point-color);
  margin: calc(var(--loader-size)*2);
}

.loader__element:nth-child(1) {
  animation: preloader .6s ease-in-out alternate infinite;
}

.loader__element:nth-child(2) {
  animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
  100% {
    transform: scale(2);
  }
}


Resources .yarnmap {
  z-index: 1;
}

.pigeon-overlays {
  top: 200px;
}

.pigeon-attribution {
  z-index: 9;
}

.map-container {
  width: 100%;
  height: calc(100% - 152px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inner-map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-wrapper {
  height: 400px;
}

.zoom-dropdown-wrapper {
  width: 100%;
  display: flex;
  min-height: 44px;
  justify-content: space-between;
  padding: 6px;
  background-color: var(--map-zoom-bar);
}

.map-filter-accordion {
  width: 300px;
  background-color: var(--map-filter-background);
  border: 1px solid var(--dark-border);
  z-index: 10;
  position: absolute;
}

.map-filter-accordion.qualities {
  background-color: var(--quality-color);
  top: 44px;
  right: 0;
}

.map-filter-accordion.brands {
  width: 100%;
  max-height: calc(100% - 46px);
  overflow: scroll;
  background-color: var(--brand-color);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.brand-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.map-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--dark-border);
}

.map-filter-button {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  background-color: var(--button-color);
  border: 1px solid var(--dark-border);
  cursor: pointer;
}

.map-filter-button.brand-color {
  background-color: var(--brand-color)
}

.map-filter-button.quality-color {
  background-color: var(--quality-color)
}

.filter-chevron {
  width: 24px;
  height: 24px;
  padding: 2px;
}

.map-filter-reset-button {
  padding: 5px 10px;
  background-color: var(--button-color);
  margin: 10px;
  border: 1px solid var(--dark-border);
  cursor: pointer;
}

.map-filter-reset-button.qualities {
  margin: 10px 10px 10px 0;
}

.map-filter-segment {
  width: 100%;
  height: 100%;
  padding: 10px 0;
}

.map-filter-heading {
  font-size: 18px;
  padding: 10px 40px;
  width: 100%;
  border-bottom: 1px solid var(--dark-border);
  display: flex;
  justify-content: space-between;
}

.map-filter-option {
  padding-left: 20px;
}

.map-filter-label {
  padding: 0 30px 5px 5px;
  font-size: 15px;
}

.search-field-container {
  display: flex;
  justify-content: space-between
}

.map-search-field {
  margin-left: 20px;
}

.map-search-field-button {
  background-color: var(--button-color);
  border: 1px solid var(--dark-border);
  padding: 5px 10px;
  margin-right: 10px;
}

.marker-button {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  z-index: 7;
  cursor: pointer;
}

.marker-label {
  background-color: white;
  height: 19px;
  position: relative;
  left: 25px;
  top: -11px;
  font-size: 14px;
  white-space: pre;
  padding: 2px;
  border-radius: 5px;
  z-index: 8;
}

.outer-destination-container {
  height: calc(100% - 102px);
  width: 100%;
}

.destination-container {
  height: 100%;
  width: 100%;
  background-color: var(--shop-card-background);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  z-index: 20;
}

.destination-card {
  width: 90%;
  border-radius: 10px;
  padding: 20px 20px 25px;
  background-color: var(--shop-card);
  box-shadow: 4px 8px 12px var(--shop-card-shadow);
  font-size: 15px;
}

.destination-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.destination-closing-link {
  position: relative;
  bottom: 10px;
  left: 10px;
}

/* .destination-header {
  padding-bottom: 5px;
} */

.destination-sub-header {
  font-size: 16px;
  padding: 5px 0 5px 40px;
}

.destination-photo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.destination-photo {
  width: 100%;
}

.destination-card__upper-info-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  word-break: break-word;
}

.destination-card__lower-info {
  font-style: italic
}

.destination-yarn-list-mobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.destination-yarn-list-tablet-desktop {
  display: none;

}

.destination-yarn-list-item {
  padding: 3px;
  margin: 3px 2px;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid var(--dark-border);
  border-radius: 5px;
  background-color: var(--highlight-color);
}

.accent-color {
  background-color: var(--button-color);
}

.accent-color:hover {
  background-color: var(--button-hover);
}

.accent-color:active {
  background-color: var(--button-active);
}

.destination-yarn-popup {
  width: 100%;
}

.destination-yarn-popup-closing-button {
  background: none;
  border: none;
  padding: 0;
  float: right;
}

.destination-card__lower-info {
  padding: 15px 0;
}

.destination-text-spacing {
  padding-top: 10px;
}

.outer-home-container {
  width: 100%;
}

.home-container {
  min-height: 80vh;
  padding: 25px 25px 100px;
  width: 100%;
  background-color: var(--shop-card-background);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text-middle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.outer-blog-container {
  width: 100%;
}

.blog-container {
  min-height: 80vh;
  padding: 25px 25px 100px;
  width: 100%;
  background-color: var(--shop-card-background);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.blog-thumbnail-link {
  padding: 10px;
}

.blog-post-link-span {
  display: flex;
  flex-direction: row;
}

.post-link-image {
  width: 75px;
  height: 75px;
}

.blog-heading {
  padding-bottom: 10px;
}

.blog-text p {
  padding-bottom: 10px;
  line-height: 1.4;
}

.blog-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog-image {
  width: 300px;
  padding-bottom: 20px;
  height: auto;
}

.blog-signature {
  padding: 10px;
}

.post-title {
  padding: 10px;
}

.home-navbar {
  display: flex;
  flex-direction: column;
}

.home-header {
  padding-bottom: 7px;
  padding-top: 10px;
}

.sub-header {
  padding-bottom: 7px;
}

.home-paragraph {
  padding-bottom: 13px;
  line-height: 1.4;
}

.contact-image {
  width: 100px;
  border-radius: 50%;
  margin-right: 40px;
  margin-top: 40px;
  border: 7px solid var(--darker-background);
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~TABLET~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media (min-width : 768px) {

  .header-container {
    height: 133px;
  }

  .header-placeholder {
    height: 133px;
  }

  .header__header-logo {
    width: 440px;
    height: 133px;
    padding: 20px;
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }

  .ad-space-wrapper {
    width: 100%;
    height: 90px;
    display: flex;
    top: 60px;
  }


  .ad-space-wrapper-home {
    display: block;
  }

  .ad-space-wrapper-home-mobile {
    display: none;
  }

  .map-filter-accordion {
    width: 400px;
    top: 44px;
  }

  .map-filter-segment {
    padding: 20px;
  }

  .map-filter-option {
    padding: 0 0 10px 20px;
  }

  .map-filter-label {
    font-size: 18px;
    cursor: pointer;
  }

  .check-box {
    cursor: pointer;
  }

  .radio-button {
    cursor: pointer;
  }

  .map-filter-button {
    top: 170px;
    font-size: 18px;
  }

  .map-filter-reset-button {
    font-size: 18px;
  }

  .map-search-field {
    font-size: 18px;
    width: 220px;
  }

  .map-search-field-button {
    font-size: 18px;
  }

  .zoom-dropdown {
    font-size: 16px;
    cursor: pointer;
  }

  .navbar {
    font-size: 18px;
    height: 79px;
  }

  .home-header {
    padding-bottom: 7px;
    padding-top: 30px;
  }

  .destination-card {
    width: 60%;
    border-radius: 10px;
    padding: 30px 30px 40px;
    margin-top: 30px;
    background-color: var(--shop-card);
    box-shadow: 4px 8px 12px var(--shop-card-shadow);
    font-size: 18px;
  }

  .destination-card__upper-info-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }

  .destination-yarn-list-mobile {
    display: none;
  }

  .destination-yarn-list-tablet-desktop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
  }

  .blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blog-heading {
    padding: 20px 0;
  }

  .blog-text-container {
    width: 570px;
  }

  .blog-signature {
    padding-bottom: 20px;
  }

  .post-title {
    font-size: 20px;
    padding: 10px;
  }

  .post-link-image {
    width: 100px;
    height: 100px;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-text-container {
    width: 570px;
  }

  .home-navbar {
    display: flex;
    flex-direction: row;
  }

  .home-navbar a {
    padding: 30px 10px 10px;
  }

  .contact-image {
    width: 150px;
    border-radius: 50%;
    margin-right: 100px;
    border: 10px solid var(--darker-background);
  }

}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~LAPTOP/DESKTOP~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@media (min-width : 1200px) {

  .app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }

  .header-container {
    height: 170.1px;
  }

  .header-placeholder {
    height: 170.1px;
  }

  .header__header-logo {
    width: 620px;
    height: 170.1px;
    padding: 10px 10px 20px;
  }

  .map-container {
    width: 100vw;
    height: calc(60vh + 44px);
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .inner-map-container {
    width: 45%;
    height: calc(60vh + 44px);
    position: relative;
  }

  .outer-home-container {
    width: 100vw;
    height: calc(60vh + 44px);
    padding-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .home-container {
    padding: 10px 50px 100px;
    width: 45%;
    height: calc(100vh - 200px);
    overflow: auto;
  }

  .home-navbar {
    display: flex;
    flex-direction: row;
  }

  .home-navbar a {
    padding: 30px 10px 0;
  }

  .outer-blog-container {
    width: 100vw;
    height: calc(60vh + 44px);
    padding-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .blog-container {
    padding: 10px 50px 100px;
    width: 45%;
    height: calc(100vh - 200px);
    overflow: auto;
  }

  .blog-image {
    width: 570px;
    padding-bottom: 30px;
  }

  .home-header {
    font-size: 28px;
    padding-bottom: 9px;
    padding-top: 10px;
  }

  .sub-header {
    font-size: 22px;
    padding-bottom: 7px;
  }

  .outer-destination-container {
    width: 100vw;
    height: calc(60vh + 44px);
    padding-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .destination-container {
    padding: 50px 50px 100px;
    width: 45%;
    height: calc(100vh - 200px);
    overflow: auto;
  }

  /*  .home-navbar {
    display: none;
  } */

  .home-text-container {
    padding: 20px;
  }

  .navbar {
    width: 14vw;
    height: 100%;
    left: 0;
    top: 250px;
    background-color: var(--contrasting-background);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 700;
    font-size: 26px;
  }

  .navbar a {
    text-decoration: none;
  }

  .ad-space-desktop-container {
    width: 28%;
    height: 610px;
    right: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .ad-space-wrapper-home {
    display: none;
  }

  .ad-space-wrapper-desktop {
    margin-bottom: 7px;
  }

  .ad-space-wrapper-home-mobile {
    display: none;
  }

  .ad-space {
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .map-filter-accordion {
    width: 500px;
    top: 50px;
  }

  .map-filter-button {
    top: 170px
  }

  .map-filter-heading {
    font-size: 25px;
    padding: 10px 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .map-filter-label {
    padding: 0 30px 5px 5px;
    font-size: 20px;
  }

  .map-search-field {
    font-size: 18px
  }

  .map-search-field-button {
    font-size: 18px;
    margin-left: 5px;
  }

  .destination-card {
    width: 30%;
    min-width: 500px;
    border-radius: 10px;
    padding: 50px 40px 50px;
    background-color: var(--shop-card);
    box-shadow: 4px 8px 12px var(--shop-card-shadow);
    font-size: 18px;
  }

  .destination-yarn-list-mobile {
    display: none;
  }

  .contact-image {
    margin-top: 10px;
  }

}